// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-calendar-js": () => import("C:\\websites\\brew-campaigns\\client\\src\\pages\\calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-create-js": () => import("C:\\websites\\brew-campaigns\\client\\src\\pages\\create.js" /* webpackChunkName: "component---src-pages-create-js" */),
  "component---src-pages-index-js": () => import("C:\\websites\\brew-campaigns\\client\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\websites\\brew-campaigns\\client\\.cache\\data.json")

